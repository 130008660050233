'use strict';

angular.module('app', [])
/* RUN BLOCK */
    .run(function () {

    })
    /* FILTER */
    .filter('precioClp', function () {
        return function (input) {
            let value = input.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
            return '$ ' + value + ' pesos';
        }
    })
    /* FACTORY */
    .factory('ReactAngular', $compile => {
        let ReactAngular = (reactElement, domElement) => {
            ReactDOM.render(reactElement, domElement[0] || domElement);
        };
        ReactAngular.$compile = (reactElement, domElement, scope) => {
            let div = document.createElement('div');
            ReactDOM.render(reactElement, div);
            angular.element(domElement).html('');
            angular.element(domElement).append($compile(div)(scope));
        };
        return ReactAngular;
    })
    /* DIRECTIVES */
    .directive('colores', function (ReactAngular, $timeout) {
        return {
            scope: {
                colores: '='
            },
            link: function (s, e) {
                function isIE() {
                    var myNav = navigator.userAgent.toLowerCase();
                    return (myNav.indexOf('msie') != -1) ? parseInt(myNav.split('msie')[1]) : false;
                }

                let Color = React.createClass({
                    propTypes: {
                        color: React.PropTypes.any,
                        index: React.PropTypes.any,
                    },
                    handleClick: function () {
                        let color = this.props.color;
                        jQuery('.main-colors').scrollTo(jQuery('#' + color.meta.sku), 250);
                    },

                    render: function () {
                        let color = this.props.color;
                        let className = 'color ' + color.category;
                        let style = {backgroundColor: '#' + color.post_content};
                        if (this.props.index > 0 && (this.props.index % 7 === 0) && color.category !== 'blancos') {
                            style.clear = 'left';
                        }
                        if (this.props.index > 0 && (this.props.index % 6 === 0) && color.category === 'blancos') {
                            style.clear = 'left';
                        }
                        return <li onClick={this.handleClick} className={className} style={style}/>;
                    }
                });

                let ColorGroup = React.createClass({
                    propTypes: {
                        colores: React.PropTypes.any
                    },
                    render: function () {
                        let className = 'color-group';
                        let open = false;
                        return <div className={className}>
                            <ul>
                                {
                                    _.map(this.props.colores, (color, index) => {

                                        return <Color key={index} color={color} index={index}/>
                                    })
                                }
                            </ul>
                            <div className="clearfix"></div>
                        </div>
                    }
                });

                let ColorList = React.createClass({
                    propTypes: {
                        gruposColores: React.PropTypes.any
                    },
                    render: function () {
                        return <div>
                            { _.map(this.props.gruposColores, (colores) => {
                                return <ColorGroup colores={colores}/>
                            }) }
                        </div>
                    }
                });

                function render(v) {
                    ReactAngular(<ColorList gruposColores={v}/>, e);
                }

                s.$watch('colores', (v) => {
                    render(v);
                })
            }
        }
    })

    /* CONTROLLERS */
    .controller('HomeController', function ($scope, $http, $timeout, $location, $interval, $sce, $log) {
        let vm = this;

        /* Properties */
        vm.actualizando = false;
        vm.quantity = {};
        vm.cartOpen = true;
        vm.quantities = [];
        vm.loading = true;
        vm.search = {
            meta: {
                sku: ''
            }
        };
        vm.activos = [];

        /* Methods */
        vm.isActive = isActive;
        vm.agregarCarro = agregarCarro;
        vm.quitarCarro = quitarCarro;
        vm.calcularTotal = calcularTotal;
        vm.quitarTodo = quitarTodo;
        vm.showCarro = showCarro;
        vm.changeQuantity = changeQuantity;
        vm.toggleCart = toggleCart;
        vm.gotoCheckout = gotoCheckout;
        vm.scrollTo = scrollTo;
        vm.toggleCarro = toggleCarro;
        vm.getShippingPrice = getShippingPrice;

        function changeQuantity(item) {
            updateCarro(item.product_cart, item.quantity);
            calcularTotal();
        }

        function toggleCart() {
            vm.cartOpen = !vm.cartOpen;
        }

        function recuperarColores() {
            $log.debug('Recuperando colores');
            for (let i = 1; i <= 100; i++) {
                vm.quantities.push(i);
            }
            $http({
                method: 'POST',
                url: '/wp-admin/admin-ajax.php',
                params: {
                    action: 'obtener_pinturas'
                }
            })
                .success(function (res) {
                    vm.loading = false;
                    colores = res;

                    try {
                        vm.colores = colores;
                        vm.coloresGrouped = _.groupBy(colores, 'category');
                        $timeout(function () {
                            $timeout(function () {
                                vm.mostrar = true;
                            });
                        }, 1000);
                        prepararCarro();
                    } catch (e) {
                        $log.error('No hay colores definidos');
                    }
                });
        }

        function getShippingPrice() {
            $http({
                method: 'POST',
                url: '/wp-admin/admin-ajax.php',
                params: {
                    action: 'shipping_price'
                }
            })
                .success(function (res) {
                    vm.shipping = res.value;
                });
        }

        function scrollTo(producto) {
            jQuery('.main-colors').scrollTo(jQuery('#' + producto.meta.sku), 250);
        }

        function showCarro() {
            vm.showCart = !vm.showCart;
        }

        function isActive(ID) {
            return _.findIndex(vm.activos, {ID: parseInt(ID)}) >= 0;
        }

        function agregarCarroScope(ID) {
            return angular.copy(_.find(vm.colores, {ID: parseInt(ID)}));
        }

        function prepararCarro() {
            try {
                _.each(carro, function (item, key) {
                    let color = agregarCarroScope(item.product_id);
                    color.product_cart = key;
                    color.quantity = item.quantity.toString();
                    vm.activos.push(color);
                })
            } catch (e) {
                $log.error('No hay carro definido', e);
            }
        }

        function agregarCarro(ID) {
            let color = agregarCarroScope(ID);
            color.quantity = "1";
            /**/
            let form = new FormData();
            form.append('action', 'add_cart');
            form.append('product_id', ID);
            vm.adding = true;
            $http({
                method: 'POST',
                url: '/wp-admin/admin-ajax.php',
                headers: {
                    'Content-Type': undefined
                },
                transformRequest: angular.identity,
                data: form
            }).success(function (res) {
                $log.debug(res);
                color.product_cart = res.product_cart;
                color.quantity = "1";
                vm.adding = false;
            });

            vm.activos.push(color);
        }


        function updateCarro(productCart, quantity) {
            let form = new FormData();
            form.append('action', 'update_cart');
            form.append('product_cart', productCart);
            form.append('quantity', quantity);
            $http({
                method: 'POST',
                url: '/wp-admin/admin-ajax.php',
                headers: {
                    'Content-Type': undefined
                },
                transformRequest: angular.identity,
                data: form
            }).success(function (res) {
                $log.debug(res);
                // color.product_cart = res.product_cart;
                // color.quantity = (new Number(quantity)).toString();
            });
        }

        function toggleCarro(ID) {
            if (isActive(ID)) {
                quitarCarro(ID);
            } else {
                agregarCarro(ID);
            }
        }


        function quitarCarro(ID) {
            vm.activos = _.remove(vm.activos, function (item) {
                if (parseInt(ID) === item.ID && item.product_cart) {
                    let form = new FormData();
                    form.append('action', 'remove_cart');
                    form.append('product_cart', item.product_cart);
                    $http({
                        method: 'POST',
                        url: '/wp-admin/admin-ajax.php',
                        headers: {
                            'Content-Type': undefined
                        },
                        transformRequest: angular.identity,
                        data: form
                    }).success(function (res) {

                    });
                }
                return parseInt(ID) !== item.ID
            });
        }

        function quitarTodo() {
            let form = new FormData();
            form.append('action', 'empty_cart');
            $http({
                method: 'POST',
                url: '/wp-admin/admin-ajax.php',
                headers: {
                    'Content-Type': undefined
                },
                transformRequest: angular.identity,
                data: form
            }).success(function (res) {

            });
            vm.activos = [];
        }

        function calcularTotal() {
            let total = 0;
            _.each(vm.activos, function (item) {
                total += parseInt(item.meta.precio * item.quantity);
            });
            return total + (vm.shipping || 0);
        }

        function gotoCheckout() {
            window.location.href = '/checkout';
        }

        /* Init function */
        recuperarColores();

        if ($location.absUrl().indexOf('/checkout') >= 0) {
            $interval(function () {
                getShippingPrice();
            }, 2500);
        }
    });